<template>
  <div class="product-container">
    <div class="header_father">
      <div class="header1 list_title">
        <van-nav-bar title="产品库" left-text="返回" right-text="创建产品" left-arrow @click-right="onClickRights"  @click-left="onClickLefts"></van-nav-bar>
        <div style="margin-top: 50px;">
        </div>
            <van-tabs v-model="active">
              <van-tab title="审核通过"></van-tab>
              <van-tab title="待审通过"></van-tab>
            </van-tabs>  
      </div>
      <div v-if="active == 0">
          <div class="texts_cs" v-if="flags"></div>
      </div>
      <div v-else>
          <div class="texts_cs" v-if="flags1"></div>
      </div>
      <van-loading size="24px" v-if="lodings" style="padding-top: 120px">加载中...</van-loading>
    </div>
      <div class="father_mr1" v-if="active == 0">
        <van-empty image="search" v-if="showEmpty" description="哎呀! 未找到相关记录..." />
          <div class="product_cs">
          </div>
          <div class="animated fadeInUp2" v-if="passProduct.length>0">
            <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
              <div class="commodityList">
                <div class="commodity_cs">
                  <van-cell is-link v-for="(item,index) in passProduct" :key='index' @click="commodityDetails(item)" class="list_cell">
                    <van-card :title="item.commodityName" class="title_img">
                      <template #thumb>
                        <van-image lazy-load v-if="item.commodityImgs" :src="item.commodityImgs[0].url" />
                        <van-image lazy-load v-else :src="require('../../assets/img/img.png')" />
                      </template>
                      <template #tags>
                        <p class="commodity"><span>产品品牌: </span>{{item.name||'-'}}</p>
                        <p class="commodity"><span>产品品类: </span>{{item.commodityContractTypeName||'-'}}</p>
                        <p class="commodity"><span>特色描述: </span>{{item.description||'-'}}</p>
                      </template>
                    </van-card>
                    <template>
                      <div style="width: 100%; background-color: #fafafa;">
                        <div class="border_cs"></div>
                      </div>
                    </template>
                  </van-cell>
                </div>
              </div>
            </van-list>
          </div>
        </div>
        <div class="father_mr1" v-else>
          <van-empty image="search" v-if="showEmpty1" description="哎呀! 未找到相关记录..." />
          <div class="product_cs">
          </div>
          <div class="animated fadeInUp2" v-if="pendingProduct.length>0">
            <van-list v-model="loading1" :finished="finished1" finished-text="没有更多了" @load="onLoad1">
              <div class="commodityList">
                <div class="commodity_cs">
                  <van-cell is-link v-for="(item,index) in pendingProduct" :key='index' @click="commodityDetails1(item)" class="list_cell">
                    <van-card :title="item.commodityName" class="title_img">
                      <template #thumb>
                        <van-image lazy-load v-if="item.commodityImgs" :src="item.commodityImgs[0].url" />
                        <van-image lazy-load v-else :src="require('../../assets/img/img.png')" />
                      </template>
                      <template #tags>
                        <p class="commodity"><span>产品品牌: </span>{{item.name||'-'}}</p>
                        <p class="commodity"><span>产品品类: </span>{{item.commodityContractTypeName||'-'}}</p>
                        <p class="commodity"><span>审核状态: </span><span style="color: red;" v-if="item.statusName.indexOf('审核未通过') != -1">{{item.statusName||'-'}}</span><span v-else>{{item.statusName||'-'}}</span></p>
                      </template>
                    </van-card>
                    <template>
                      <div style="width: 100%; background-color: #fafafa;">
                        <div class="border_cs"></div>
                      </div>
                    </template>
                  </van-cell>
                </div>
              </div>
            </van-list>
          </div>
        </div>
    <div class="title_serch">
    </div>
    <van-tabbar route>
      <van-tabbar-item to="/productAudit" icon="wap-nav">产品提报库</van-tabbar-item>
      <van-tabbar-item to="/brand" icon="card">品牌库</van-tabbar-item>
      <van-tabbar-item to="/product" icon="gift-card">产品库</van-tabbar-item>
      <van-tabbar-item to="/mine" icon="manager">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import { Notify } from 'vant'
export default {
  data() {
    return {
      active: 0,
      showEmpty: false,
      showEmpty: false,
      showEmpty1: false,
      loading: false,
      loading1: false,
      lodings: true,
      flags: true,
      flags1: true,
      finished: false,
      finished1: false,
      query: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
      passProduct: [],//审核通过产品列表
      pendingProduct: [],//待审核通过产品列表
    }
  },
  created() {
    if(this.$route.query.active != undefined ){
       this.active = Number(this.$route.query.active)
    }
    this.lodings = true;
    this.flags = false;
    this.flags1 = false;
    setTimeout(()=>{
      this.lodings = false;
      this.getPassProduct();
      this.getpendingProduct();
    }, 300)
  },
  methods: {
    onClickRights(){
        this.$router.push({
          path: '/addproduct',
          query: {}
        })
    },
    // 获取审核通过产品列表
    getPassProduct(){
      this.$http
        .ajax(
          '/ffkj-main/merchantCommodity/page',
          {
            pageNum: this.query.pageNum,
            pageSize: this.query.pageSize,
            merchantId: localStorage.getItem('merchantId'),
            pageEnum: 'QUERY_PAGE',
          },
          'get',
          {}
        )
        .then(res => {
          if (res.code == 0) {
            this.flags = true;
            let rows = res.data.list
            this.passProduct = this.passProduct.concat(rows)
            if(res.data.total > 0){
              this.flags = true;
              this.showEmpty = false;
            }else{
              this.flags = false;
              this.showEmpty = true;
            }
            if (this.passProduct.length >= res.data.total) {
              // 加载结束
              this.finished = true
              return
            } else {
              this.finished = false
            }
          } else {
            Notify({ type: 'danger', message: res.message })
          }
          if (this.passProduct == null || this.passProduct.length == 0) {
            // 加载结束
            this.finished = true
            return
          }
          var timer = setTimeout(
            function () {
              window.clearTimeout(timer)
            }.bind(this),
            3000
          )
        })
      this.loading = false
    },
    // 待审通过产品
    getpendingProduct() {
      this.$http
        .ajax(
          '/ffkj-main/merchantCommodity/page',
          {
            pageNum: this.query1.pageNum,
            pageSize: this.query1.pageSize,
            merchantId: localStorage.getItem('merchantId'),
            pageEnum: 'EDIT_PAGE',
          },
          'get',
          {}
        )
        .then(res => {
          if (res.code == 0) {
            this.flags1 = true;
            let rows = res.data.list
            this.pendingProduct = this.pendingProduct.concat(rows)
            if(res.data.total > 0){
              this.flags1 = true;
              this.showEmpty1 = false;
            }else{
              this.flags1 = false;
              this.showEmpty1 = true;
            }
            if (this.pendingProduct.length >= res.data.total) {
              // 加载结束
              this.finished1 = true
              return
            } else {
              this.finished1 = false
            }
          } else {
            Notify({ type: 'danger', message: res.message })
          }
          if (this.pendingProduct == null || this.pendingProduct.length === 0) {
            // 加载结束
            this.finished1 = true
            return
          }
          var timer = setTimeout(
            function () {
              this.loading = false
              window.clearTimeout(timer)
            }.bind(this),
            3000
          )
        })
      this.loading1 = false
    },
    onClickLefts() {
      window.history.go(-1);
    },
    newProductDetails() {
      this.$router.push({
        path: '/productDetails',
        query: {}
      })
    },
    commodityDetails(item) {
      this.$router.push({
        path: '/productDetails',
        query: {
          id: item.id
        }
      })
    },
    commodityDetails1(item) {
      this.$router.push({
        path: '/productDetails1',
        query: {
          id: item.id
        }
      })
    },
    //滚动加载时触发，list组件定义的方法
    onLoad() {
      setTimeout(
        function () {
          this.query.pageNum++
          this.getPassProduct();
        }.bind(this),
        3000
      )
    },
    //滚动加载时触发，list组件定义的方法
    onLoad1() {
      setTimeout(
        function () {
          this.query1.pageNum++
          this.getpendingProduct();
        }.bind(this),
        3000
      )
    }
  },
}
</script>

<style>

</style>